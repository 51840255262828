import config from '../../config';

/**
 * Requests a pixel image.
 * @returns {promise} - resolves to true if the image was successfully retrieved, resolves to false if the image retrieval failed or the timeout exceeded
 */
const pixelTest = (testVersion) => new Promise((resolve) => {
  const cacheBuster = Math.random() * 11;
  const url = `${config.adblock.pixel.url}?ch=${testVersion}&rn=${cacheBuster}`;

  const image = new Image();
  image.src = url;
  image.onload = () => {
    resolve(true);
  };
  image.onerror = () => {
    resolve(false);
  };

  setTimeout(() => {
    resolve(false);
  }, 1000);
});

/**
 * Performs both pixel tests for the testing of acceptable ads.
 * @returns {promise} - Resolves to an array with the returned values for each pixel test.
 */
const pixelTests = () => {
  const p1 = pixelTest(1);
  const p2 = pixelTest(2);

  return Promise.all([p1, p2]);
};

export default {
  pixelTest,
  pixelTests
};
