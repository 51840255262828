import pixelTest from './pixel-test';
import { setAdblockStorage } from './set-adblock-storage';

/**
 * Set up the global window space for adblock.
 */
const initialize = () => {
  window.meso = window.meso || {};
  // TODO: Enable this once the adblock detect script is added.
  // window.meso.adblock = window.meso.adblock || {};
};

/**
 * Detect adblock settings and assign them to the global window space.
 * @returns {promise} - promise resolves with the adblock settings if successful, or an error message if something goes wrong
 */
const detect = () =>
  new Promise((resolve) => {
    // Initialize the adblock framework.
    initialize();
    pixelTest.pixelTests().then((values) => {
      if (values[0] === true) {
        // Adblock detected and acceptable ads allowed.
        if (values[1] === false) {
          window.meso.adblock.enabled = true;
          window.meso.adblock.acceptableads = true;
        }
        // Adblock detected and acceptable ads not allowed.
        else if (window.meso.adblock.enabled) {
          window.meso.adblock.acceptableads = false;
        }
      }
      else if (values[1] === false) {
        // Unexpected. Something is likely wrong with the pixels.
        resolve(new Error('unexpected problem with pixel fetch'));
      }

      setAdblockStorage(window.meso.adblock);
      // Resolve with adblock settings from adblock script.
      resolve(window.meso.adblock);
    });
  });

export { detect, initialize };
